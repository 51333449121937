<template>
  <div class="mine-service">
    <div class="mine-service-header">
      <div class="mine-service-header-left">
        <strong>我发布的服务</strong>
      </div>
      <div class="mine-service-header-right">
        <button>发布服务</button>
      </div>
    </div>
    <div class="mine-service-bottom">
       <div class="mine-service-bottom-header">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="所有项目" name="first">
            <div class="mine-service-bottom-all-program">
              <div class="mine-service-bottom-all-program-center">
                <MineService></MineService>
                <MineService></MineService>
                <MineService></MineService>
                <MineService></MineService>
              </div>
            </div>
            
          </el-tab-pane>
          <el-tab-pane label="移动开发" name="second">移动开发</el-tab-pane>
          <el-tab-pane label="电子商务网站" name="third">电子商务网站</el-tab-pane>
          <el-tab-pane label="AngularJS | 节点JS" name="fourth"
            >AngularJS | 节点JS</el-tab-pane
          >
        </el-tabs>
        <div class="mine-service-bottom-header-right">
          <img src="@/icons/svg/person/搜索16X16.svg" alt="">
          <input type="text" name="" id="" placeholder="请输入关键字搜索">
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
import MineService from "@/components/MineService"
export default {
  components:{
    MineService
  },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style lang="less" scoped>
.mine-service {
  background-color: #fff;
  .mine-service-header {
    height: 77px;
    display: flex;
    border-bottom: 1px solid #b5b5b5;
    justify-content: space-between;
    .mine-service-header-left,
    .mine-service-header-right {
      margin-top: 38px;
    }
    .mine-service-header-left {
      margin-left: 40px;
      strong {
        font-size: 18px;
      }
    }
    .mine-service-header-right {
      margin-right: 40px;
      button {
        width: 100px;
        height: 24px;
        background-color: #00a2e6;
        color: #fff;
        font-size: 12px;
      }
    }
  }
  .mine-service-bottom{
    width: 860px;
    margin: 0 auto;
    margin-top: 30px;
    .mine-service-bottom-header{
      display: flex;
      justify-content: space-between;
      .mine-service-bottom-all-program{
        width: 960px;
        .mine-service-bottom-all-program-center{
          width: 990px;
          display: flex;
          flex-wrap: wrap;
        }
      }
      .mine-service-bottom-header-right{
       input{
         width: 200px;
         height: 32px;
         border: 1px solid #b5b5b5;
         text-indent: 10px;
         input[placeholder]{
           color: #474747;
         }
       }
       img{
         width: 16px;
         height: 16px;
         position: relative;
         right: -190px;
         top: 3.5px;
       }
      }
    }
  }
}
/deep/.el-tabs__item.is-active{
  color: #e4007f;
}
/deep/.el-tabs__active-bar{
  background-color: #e4007f;
}
/deep/.el-tabs__item:hover{
  color: #e4007f;
}
/deep/.el-tabs{
  width: 500px;
  #tab-first{
    padding-left: 20px;
  }
  #tab-fourth{
    padding-right: 20px;
  }
}
/deep/.el-tabs__content{
  width: 860px;
}
</style>